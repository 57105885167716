import { useCanCan, useModel, useService } from '@thrivelot/hooks';
import { Button, Card, CardLoader } from '@thrivelot/stories';
import React from 'react';

const CardProjectUseInvoiceProposals = ({ projectId }) => {
  const {
    model: project,
    loaded,
    updateModel,
    actions,
  } = useModel({ modelName: 'Project', id: projectId });
  const { request: sendRequest, loading: loadingRequest } = useService({
    method: 'post',
    level: 'admin',
    path: 'use-invoice-proposals',
    deferLoad: true,
    variables: { projectId },
  });
  const { isAdmin } = useCanCan(project);

  if (!loaded) return <CardLoader />;

  const useInvoiceProposals = !!project?.details?.useInvoiceProposals;
  const isInvoiceProposalsEmpty =
    useInvoiceProposals && (project?.invoiceProposalIds || []).length === 0;

  return (
    <Card header="Order BETA" classAppend="mb-4">
      {useInvoiceProposals ? (
        <div className="flex flex-col gap-6 text-center">
          <div>
            This project is using the OLD cost payment schedule for payments.
          </div>
          <Button
            block
            color="red-main"
            kind="outline"
            label="Revert to Cost Payment Schedule"
            onClick={() =>
              updateModel(
                actions.set('details.useInvoiceProposals', false).result
              )
            }
          />
        </div>
      ) : (
        <div className="flex flex-col gap-6 text-center">
          <div>
            This project is using the OLD cost payment schedule for payments.
          </div>
          <Button
            block
            color="green-main"
            label="Enable Orders"
            onClick={() =>
              updateModel(
                actions.set('details.useInvoiceProposals', true).result
              )
            }
          />
        </div>
      )}

      {isAdmin || isInvoiceProposalsEmpty ? (
        <div className="flex flex-col mt-6 gap-6 text-center">
          <div>
            {isInvoiceProposalsEmpty
              ? 'There are currently no Orders for this project. Click below to populate Orders from existing Features and Line Items. Please verify the data after populating.'
              : 'As an admin, you can run this migration even though orders already exist. Be warned: this will create duplicates if orders have previously been migrated. Please verify the data after populating.'}
          </div>
          <Button
            block
            loading={loadingRequest}
            color="blue-main"
            kind="outline"
            label="Populate Orders"
            onClick={() => sendRequest()}
          />
        </div>
      ) : null}
    </Card>
  );
};

export { CardProjectUseInvoiceProposals };
