import { Card } from "@thrivelot/stories";
import { CardLoader } from "@thrivelot/stories";
import React from "react";
import { useModal, useModel } from "@thrivelot/hooks";
import { ServiceProviderFromOwnerId } from "./ServiceProviderFromOwnerId";
import { Checkmark, Edit, Visibility } from "@thrivelot/stories";
import { Link } from "react-router-dom";

const CardProjectServiceProviders = ({ projectId, editable, showProfileLinks }) => {
  const { openModal } = useModal();
  const { model: project, loaded } = useModel({ modelName: "Project", id: projectId });

  if (!loaded) return <CardLoader showText />;

  const { owners } = project;

  return (
    <Card
      header="Service Providers"
      headerActions={
        editable && (
          <Edit
            color="yellow-dark"
            styleProps="hover:cursor-pointer"
            size="20"
            onClick={() =>
              openModal({ path: "ModalEditServiceProviders", projectId, customerId: project?.customer?.id })
            }
          />
        )
      }
      classAppend="mb-4"
    >
      {owners?.length > 0 ? (
        <div className="flex flex-col gap-2">
          {owners.map((ownerId) => (
            <ServiceProviderFromOwnerId
              key={ownerId}
              ownerId={ownerId}
              renderDetails={(supplier) => (
                <div className="flex items-center">
                  {owners[0] === ownerId && <Checkmark size={20} color="green-main" />}
                  {showProfileLinks && (
                    <Link to={`/suppliers/${supplier.id}`} className="ml-2">
                      <Visibility color="tan-main" size={20} />
                    </Link>
                  )}
                </div>
              )}
            />
          ))}
        </div>
      ) : (
        <div className="italic text-brown-dark text-sm">No service providers assigned...</div>
      )}
    </Card>
  );
};

export { CardProjectServiceProviders };
