import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { RowItemLoader, Cost } from '@thrivelot/stories';
import { formatMediumDateTime } from '@thrivelot/date';
import { useModel, useModelList } from '@thrivelot/hooks';
import { transformCurrencyToString } from '@thrivelot/utils';
import { useInvoiceProposalAndBillingSummary } from './useInvoiceProposalAndBillingSummary';

const statusMap = {
  unpaid: 'unpaid',
  'needs-verification': 'needsVerification',
  'partially-paid': 'partial',
  paid: 'paid',
};

const safeSlug = (value) => value || '';

const Block = ({ label, content, breakWords = false, contentColor = null }) => (
  <>
    <div className="block w-1/3 md:w-1/4 lg:w-1/6">
      <div className="text-xs">{label}</div>
      <div
        className={`font-bold text-sm ${breakWords ? 'break-words' : ''} ${
          contentColor ? `text-${contentColor}` : ''
        }`}
      >
        {content}
      </div>
    </div>
    <div className="bg-tan-light w-[1px]" />
  </>
);

const Supplier = ({ owner, updateSlug }) => {
  const { models: suppliers, loaded } = useModelList({
    modelName: 'Supplier',
    filter: { owners: { contains: owner } },
  });

  useEffect(() => {
    if (!loaded) return;

    const supplier = suppliers?.[0] || null;
    if (!supplier) updateSlug('');

    const slug = `${safeSlug(supplier?.email)}${safeSlug(
      supplier?.hubspotId
    )}`.toLowerCase();
    updateSlug(slug);
  }, [suppliers, loaded, updateSlug]);

  if (!loaded)
    return (
      <>
        <Block label="SP Email" content="Loading..." />
        <Block label="SP HubSpot ID" content="Loading..." />
      </>
    );

  const supplier = suppliers?.[0] || null;
  if (!supplier)
    return (
      <>
        <Block label="SP Email" content="N/A" />
        <Block label="SP HubSpot ID" content="N/A" />
      </>
    );

  return (
    <>
      <Block label="SP Email" content={supplier.email || 'N/A'} breakWords />
      <Block
        label="SP HubSpot ID"
        content={supplier.hubspotId || 'N/A'}
        breakWords
      />
    </>
  );
};

const Project = ({ projectId, updateSlug }) => {
  const { model: project, loaded } = useModel({
    modelName: 'Project',
    id: projectId,
  });

  useEffect(() => {
    if (!loaded) return;

    const slug = `${safeSlug(project?.hubspotId)}${safeSlug(
      project?.customer?.email
    )}${safeSlug(project?.customer?.hubspotId)}`.toLowerCase();
    updateSlug(slug);
  }, [
    project?.hubspotId,
    project?.customer?.email,
    project?.customer?.hubspotId,
    loaded,
    updateSlug,
  ]);

  if (!loaded)
    return (
      <>
        <Block label="Project HubSpot ID" content="Loading..." />
        <Block label="Customer Email" content="Loading..." />
        <Block label="Customer HubSpot ID" content="Loading..." />
      </>
    );

  return (
    <>
      <Block
        label="Project HubSpot ID"
        content={project.hubspotId || 'N/A'}
        breakWords
      />
      <Block
        label="Customer Email"
        content={project.customer?.email || 'N/A'}
        breakWords
      />
      <Block
        label="Customer HubSpot ID"
        content={project.customer?.hubspotId || 'N/A'}
        breakWords
      />
    </>
  );
};

const RowItemOrder = ({
  invoiceProposalId,
  status: urlStatus,
  updateSlugs,
}) => {
  const { invoiceProposal, actions, billing, loaded } =
    useInvoiceProposalAndBillingSummary(invoiceProposalId);
  const [projectSlug, setProjectSlug] = useState();
  const [supplierSlug, setSupplierSlug] = useState();

  const needsVerification = useMemo(
    () =>
      loaded &&
      (invoiceProposal?.payments || []).filter(
        (payment) => payment.stripeObjectStatus === 'verification_required'
      ).length > 0,
    [loaded, invoiceProposal?.payments]
  );
  const completedPayments = useMemo(
    () =>
      loaded &&
      (invoiceProposal?.payments || []).filter((payment) => payment.paidAt),
    [invoiceProposal?.payments, loaded]
  );
  const paymentStatus = useMemo(() => {
    if (!loaded) return null;
    if (needsVerification) return 'needsVerification';
    if (
      completedPayments.length > 0 &&
      completedPayments.length === actions.getPaymentCount()
    )
      return 'paid';
    if (
      completedPayments.length > 0 &&
      completedPayments.length !== actions.getPaymentCount()
    )
      return 'partial';
    return 'unpaid';
  }, [actions, completedPayments.length, loaded, needsVerification]);

  useEffect(() => {
    if (!loaded || !projectSlug || !supplierSlug) return;

    let didCancel;

    const slug = `${safeSlug(invoiceProposal?.name)}${safeSlug(
      invoiceProposal?.id
    )}${safeSlug(invoiceProposal?.projectId)}${safeSlug(projectSlug)}${safeSlug(
      supplierSlug
    )}`.toLowerCase();

    if (!didCancel) updateSlugs(invoiceProposal.id, slug);

    // eslint-disable-next-line consistent-return
    return () => {
      didCancel = true;
    };
  }, [
    invoiceProposal?.name,
    invoiceProposal?.id,
    invoiceProposal?.projectId,
    loaded,
    projectSlug,
    supplierSlug,
    updateSlugs,
  ]);

  const updateProjectSlug = useCallback(
    () => (slug) => {
      if (projectSlug !== slug) setProjectSlug(slug);
    },
    []
  );

  const updateSupplierSlug = useCallback(
    () => (slug) => {
      if (supplierSlug !== slug) setSupplierSlug(slug);
    },
    []
  );

  if (!loaded) return <RowItemLoader />;
  if (urlStatus !== 'orders' && paymentStatus !== statusMap[urlStatus])
    return null;

  return (
    <div className="block w-full p-6">
      <div className="flex gap-4 mb-4">
        <Cost color="brown-dark" size={24} />
        <div className="text-lg font-bold">{invoiceProposal.name}</div>
      </div>
      <div className="flex flex-wrap gap-2 w-full mb-4">
        <Block label="Order ID" content={invoiceProposal.id} breakWords />
        <Block
          label="Project ID"
          content={invoiceProposal.projectId}
          breakWords
        />
        <Project
          projectId={invoiceProposal.projectId}
          updateSlug={updateProjectSlug(projectSlug)}
        />
        <Supplier
          owner={invoiceProposal.owners?.[0]}
          updateSlug={updateSupplierSlug(supplierSlug)}
        />
      </div>
      {billing?.payments && (
        <>
          {(billing?.payments || []).map((payment, i) => {
            const payout = (billing?.payouts || []).find(
              ({ paymentLabel }) => paymentLabel === payment.label
            );
            return (
              <div
                className={`p-2 border rounded border-tan-dark ${
                  billing.payments.length - 1 !== i ? 'mb-4' : ''
                }`}
              >
                <div className="font-bold text-sm mb-1">Payment {i + 1}</div>
                <div className="flex flex-wrap gap-2 w-full">
                  <Block
                    label="Total Amount"
                    content={transformCurrencyToString(payment.totalAmount)}
                    breakWords
                  />
                  <Block
                    label="Payout Amount"
                    content={transformCurrencyToString(payout.totalAmount)}
                    breakWords
                  />
                  <Block
                    label="House Amount"
                    content={transformCurrencyToString(payment.houseSubtotal)}
                    breakWords
                  />
                  <Block
                    label="Payment Date"
                    content={
                      payment.paidAt
                        ? formatMediumDateTime(payment.paidAt)
                        : 'UNPAID'
                    }
                    breakWords
                    contentColor={payment.paidAt ? 'green-main' : 'red-main'}
                  />
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export { RowItemOrder };
