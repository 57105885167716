import React from 'react';
import { RowListItem } from '@thrivelot/stories';
import { PlantPic } from './PlantPic';

export const RowItemPlant = ({ plant }) => {
  const { id, commonName, genus, species } = plant;

  const scientificName = `${genus || ''} ${species || ''}`;
  return (
    <RowListItem
      to={id}
      thumbnail={<PlantPic plant={plant} />}
      header={commonName || scientificName}
      subheader={commonName ? scientificName : null}
    />
  );
};
