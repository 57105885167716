import React from 'react';
import { RowItemLoader, RowList } from '@thrivelot/stories';
import { RowItemSupplier } from '@thrivelot/components';

const RenderSuppliers = ({ supplierFilter, suppliers, isLoaded }) => {
  const renderSuppliers = () => {
    const filteredSuppliers = supplierFilter
      ? suppliers.filter((supplier) => supplier.id === supplierFilter.value)
      : suppliers;

    return filteredSuppliers.map((supplier) => (
      <RowItemSupplier key={supplier.id} supplier={supplier} />
    ));
  };

  if (!isLoaded) return <RowItemLoader showText />;

  return <RowList>{renderSuppliers()}</RowList>;
};

export { RenderSuppliers };
