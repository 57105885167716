import React, { useState } from 'react';
import { useModelList } from '@thrivelot/hooks';
import { PageContainer, LeafLoader } from '@thrivelot/stories';
import { sortComplex } from '@thrivelot/utils';
import { RenderCustomers } from './RenderCustomers';
import { SearchBar } from './SearchBar';

const PageExploreCustomers = () => {
  const { models, loaded, loading } = useModelList({ modelName: 'Customer' });
  const [customerFilter, setCustomerFilter] = useState(null);
  const customers = sortComplex({
    arr: models,
    by: 'firstname',
    backupBy: 'email',
  });

  return (
    <PageContainer
      header={
        <div className="flex items-end">
          <div className="mr-5">Customers</div>
          {loading && <LeafLoader size="sm" classAppend="mb-1" />}
        </div>
      }
      documentTitle="Customers"
    >
      <div className="my-5">
        <SearchBar
          customerFilter={customerFilter}
          setCustomerFilter={setCustomerFilter}
          customers={customers}
        />
      </div>
      <RenderCustomers
        customerFilter={customerFilter}
        customers={customers}
        isLoaded={loaded}
      />
    </PageContainer>
  );
};

export { PageExploreCustomers };
