import React from 'react';
import { ImgCircle } from '@thrivelot/styles';
import { User } from '@thrivelot/stories';

const SupplierPic = ({ supplier }) => {
  const { pic } = supplier;

  if (!pic || !pic.key || pic.key === '') return <User size={40} />;

  return <ImgCircle src={pic} size={40} />;
};

export default SupplierPic;
