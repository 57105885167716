import React from 'react';
import { useModal } from '@thrivelot/hooks';
import { Button } from '@thrivelot/stories';

const SearchBar = ({ query, setQuery }) => {
  const { openModal } = useModal();

  return (
    <div className="my-5 relative flex">
      <input
        className="input"
        placeholder="Search plant database"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <Button
        classAppend="ml-3"
        size="small"
        kind="outline"
        color="green-main"
        icon="Plus"
        label="Create"
        onClick={() => openModal({ path: 'ModalAddPlant' })}
      />
    </div>
  );
};

export { SearchBar };
