import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import {
  useAuth,
  useMarker,
  ModelKitProvider,
  PlantsProvider,
} from '@thrivelot/hooks';
import { constructUuid } from '@thrivelot/utils';
import { authConfig, modelConfig } from '@thrivelot/config';
import {
  LayoutSimple,
  LayoutProject,
  LayoutSupplier,
  LayoutSuspense,
  RoutingAuthentication,
  PageProjectFeatures,
  PagePreviewProjectInvoiceProposal,
  PagePreviewProjectInvoiceProposalProposal,
  PagePreviewProjectInvoiceProposalInvoice,
  PageViewInvoiceProposalPlantPalette,
  PageProjectInvoiceProposals,
  PageProjectFiles,
  // PageProjectHistory,
  PageProjectMap,
  PageProjectNotes,
  PageProjectPaymentSchedule,
  PageProjectPhases,
  PageUnchartedLands,
} from '@thrivelot/components';
import {
  PageCustomerDetails,
  PageEditPlant,
  PageExploreCustomers,
  PageExploreOrders,
  PageExplorePlants,
  PageExploreProjects,
  PageExploreSuppliers,
  PageSupplierAdmin,
  PageSupplierProfile,
  PageSupplierProjects,
  PageSupplierCatalog,
  PageSupplierCatalogImport,
  PageSettings,
  PageProjectAdmin,
  PageProjectDetails,
} from '../pages';
import { CustomerProposal } from '../CustomerProposal';
import { PageEditProjectInvoiceProposal } from '../pages/PageEditProjectInvoiceProposal/PageEditProjectInvoiceProposal';
// import { PageAdminTools } from '../pages/PageAdminTools';

const Routing = () => {
  const auth = useAuth();
  const { handleMarker } = useMarker();
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (auth.authenticated && !auth.groups.includes('admin')) auth.signOut();
  }, [auth, auth?.authenticated, auth?.groups, auth?.signOut]);

  useEffect(() => {
    if (
      /thrivelot.com/.test(window.location.hostname) &&
      auth?.authenticated &&
      auth?.userId &&
      auth?.user?.attributes
    ) {
      try {
        window.Marker.setReporter({
          email: auth?.user?.attributes?.email,
          fullName: auth?.user?.attributes?.name,
        });

        window.pendo.initialize({
          visitor: {
            id: auth?.userId, // Required if user is logged in, default creates anonymous ID
            email: auth?.user?.attributes?.email, //  Recommended if using Pendo Feedback, or NPS Email
            full_name: auth?.user?.attributes?.name, // Recommended if using Pendo Feedback
            role: 'supplier',

            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
          },
        });
      } catch (e) {
        console.log('Marker not loaded', e);
      }
    }
  }, [auth?.authenticated, auth?.userId, auth?.user?.attributes]);

  const onUpdateVariableInput = useCallback(
    (input) => ({
      ...input,
      updatedMeta: {
        id: constructUuid(),
        userCognitoId: auth?.user?.username,
        userId: auth?.userId,
        userEmail: auth?.user?.attributes?.email,
        userName: auth?.user?.attributes?.name,
        userGroups: auth?.groups && JSON.stringify(auth.groups),
        device: navigator.userAgent,
      },
    }),
    [auth]
  );

  const navConfig = useMemo(
    () => ({
      helpOptions: [
        {
          label: '🎬 Thrive Lot Video Tutorials',
          href: 'https://thrivelot.notion.site/thrivelot/Platform-Update-November-21-7ce8caa80c67423f8591bcf5e1787d07',
        },
        {
          label: '🐞 Submit Bug or Feature Request',
          onClick: handleMarker,
        },
        {
          label: '🚨 Trigger error boundary test',
          onClick: () => setShowError(true),
        },
      ],
      navOptions: [
        {
          label: 'Customers',
          to: 'customers',
          icon: 'UserGroup',
        },
        {
          label: 'Suppliers',
          to: 'suppliers',
          icon: 'UserGroup',
        },
        {
          label: 'Projects',
          to: 'projects',
          icon: 'Plan',
        },
        {
          label: 'Plants',
          to: 'plants',
          icon: 'Leaf',
        },
        {
          label: 'Orders',
          to: 'orders',
          icon: 'LineItems',
        },
      ],
      projectNavOptions: [
        {
          label: 'Details',
          icon: 'Book',
          to: 'details',
        },
        {
          label: 'Notes',
          icon: 'Sketch',
          to: 'notes',
        },
        {
          label: 'Files',
          icon: 'FilesFolder',
          to: 'files',
        },
        {
          label: 'Map',
          icon: 'Map',
          to: 'map',
        },
        // {
        //   label: 'Features',
        //   icon: 'Feature',
        //   to: 'features',
        // },
        {
          label: 'Phases',
          icon: 'Phases',
          to: 'phases',
          hide: (project) => !!project?.details?.useInvoiceProposals,
        },
        {
          label: 'Payment Schedule',
          icon: 'Cost',
          to: 'payment-schedule',
          hide: (project) => !!project?.details?.useInvoiceProposals,
        },
        {
          label: 'Orders',
          icon: 'LineItems',
          to: 'orders',
          hide: (project) => !project?.details?.useInvoiceProposals,
        },
        {
          label: 'Admin',
          icon: 'Console',
          to: 'admin',
        },
      ],
      supplierNavOptions: [
        {
          label: 'Profile',
          icon: 'User',
          to: 'profile',
        },
        {
          label: 'Projects',
          to: 'projects',
          icon: 'Plan',
        },
        {
          label: 'Catalog',
          to: 'catalog',
          icon: 'Catalog',
        },
        { label: 'Admin', to: 'admin', icon: 'Console' },
      ],
    }),
    [handleMarker]
  );

  if (auth.loading) return <LayoutSuspense />;

  if (showError) return <div>{(10).sort()}</div>;

  if (window.location.pathname.includes('/proposal')) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/proposal/*" element={<CustomerProposal />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      {auth.authenticated ? (
        <ModelKitProvider
          authConfig={authConfig}
          modelConfig={{ ...modelConfig, onUpdateVariableInput }}
          auth={auth}
        >
          <PlantsProvider>
            <Routes>
              <Route path="/" element={<LayoutSimple navConfig={navConfig} />}>
                {/* If you're logged in and on / or /auth redirect to projects */}
                <Route path="auth/*" element={<Navigate to="/projects" />} />
                <Route exact path="" element={<Navigate to="/projects" />} />

                {/* Top level page routing */}

                {/* <Route exact path="admin-tools" element={<PageAdminTools />} /> */}

                <Route path="orders">
                  <Route exact path="" element={<PageExploreOrders />} />
                  <Route exact path="*" element={<PageExploreOrders />} />
                </Route>

                <Route path="settings">
                  <Route exact path="" element={<PageSettings />} />
                </Route>

                <Route path="plants">
                  <Route path="" element={<PageExplorePlants />} />
                  <Route path=":plantId" element={<PageEditPlant />} />
                </Route>

                <Route path="customers">
                  <Route exact path="" element={<PageExploreCustomers />} />
                  <Route
                    exact
                    path=":customerId"
                    element={<PageCustomerDetails />}
                  />
                </Route>

                <Route path="suppliers">
                  <Route exact path="" element={<PageExploreSuppliers />} />
                  <Route
                    path=":supplierId"
                    element={<LayoutSupplier navConfig={navConfig} />}
                  >
                    <Route
                      exact
                      path=""
                      element={<Navigate to="profile" replace />}
                    />
                    <Route path="profile" element={<PageSupplierProfile />} />
                    <Route path="projects" element={<PageSupplierProjects />} />
                    <Route path="admin" element={<PageSupplierAdmin />} />
                    <Route path="catalog">
                      <Route path="" element={<PageSupplierCatalog />} />
                      <Route
                        path="import"
                        element={<PageSupplierCatalogImport />}
                      />
                    </Route>
                  </Route>
                </Route>

                <Route path="projects">
                  <Route exact path="" element={<PageExploreProjects />} />

                  <Route
                    path=":projectId"
                    element={<LayoutProject navConfig={navConfig} />}
                  >
                    <Route
                      exact
                      path=""
                      element={<Navigate to="details" replace />}
                    />
                    <Route path="details" element={<PageProjectDetails />} />
                    <Route path="files/*" element={<PageProjectFiles />} />
                    <Route path="map" element={<PageProjectMap />} />
                    <Route path="map">
                      <Route exact path="" element={<PageProjectMap />} />
                      <Route
                        path="features/*"
                        element={<PageProjectFeatures />}
                      />
                    </Route>
                    <Route path="notes" element={<PageProjectNotes />} />
                    <Route
                      path="payment-schedule"
                      element={<PageProjectPaymentSchedule />}
                    />
                    <Route path="phases" element={<PageProjectPhases />} />
                    <Route path="orders">
                      <Route
                        exact
                        path=""
                        element={<PageProjectInvoiceProposals />}
                      />
                      <Route
                        path=":invoiceProposalId"
                        element={<PagePreviewProjectInvoiceProposal />}
                      />
                      <Route
                        path=":invoiceProposalId/proposal"
                        element={<PagePreviewProjectInvoiceProposalProposal />}
                      />
                      <Route
                        path=":invoiceProposalId/plant-palette"
                        element={<PageViewInvoiceProposalPlantPalette />}
                      />
                      <Route
                        path=":invoiceProposalId/invoices/:paymentIndex"
                        element={<PagePreviewProjectInvoiceProposalInvoice />}
                      />
                      <Route path=":invoiceProposalId/edit">
                        <Route
                          exact
                          path=""
                          element={<PageEditProjectInvoiceProposal />}
                        />
                        <Route
                          path="features/*"
                          element={<PageProjectFeatures />}
                        />
                      </Route>
                    </Route>
                    <Route path="admin" element={<PageProjectAdmin />} />
                    {/* <Route path="history" element={<PageProjectHistory />} /> */}
                  </Route>
                </Route>

                {/* Handle 404 */}
                <Route path="*" element={<PageUnchartedLands />} />
              </Route>
            </Routes>
          </PlantsProvider>
        </ModelKitProvider>
      ) : (
        <RoutingAuthentication />
      )}
    </BrowserRouter>
  );
};

export { Routing };
