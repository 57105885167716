/* eslint-disable no-nested-ternary */
import React from 'react';
import { usePlantSearch } from '@thrivelot/hooks';
import {
  EmptyState,
  PageContainer,
  RowItemLoader,
  RowList,
} from '@thrivelot/stories';
import { isEmpty } from '@thrivelot/utils';
import { RowItemPlant } from './RowItemPlant';
import { SearchBar } from './SearchBar';

export const PageExplorePlants = () => {
  const { query, setQuery, items, loading } = usePlantSearch();

  return (
    <PageContainer header="Plant Database" documentTitle="Orders">
      <SearchBar query={query} setQuery={setQuery} />
      {loading ? (
        <RowItemLoader showText />
      ) : isEmpty(items) && !isEmpty(query) ? (
        <EmptyState>
          No plants found for{' '}
          <span className="font-bold text-mulch-dark">{query}</span>...
        </EmptyState>
      ) : isEmpty(items) ? (
        <EmptyState>
          Search plants by common name, scientific name or family...
        </EmptyState>
      ) : (
        <RowList>
          {items.map((item) => (
            <RowItemPlant key={item.id} plant={item} />
          ))}
        </RowList>
      )}
    </PageContainer>
  );
};
