import { useModel, useService } from "@thrivelot/hooks";
import { useCallback, useEffect, useState } from "react";
import { fetchCognitoUserByEmail } from "../../utils";

const groupMap = {
  Customer: "customer",
  Supplier: "supplier",
};

const useCognitoStatus = ({ modelName, id }) => {
  const group = groupMap[modelName];
  const [loadingCognito, setLoadingCognito] = useState(false);
  const [loadedCognito, setLoadedCognito] = useState(false);
  const [cognitoStatus, setCognitoStatus] = useState();

  const { model, loaded, loading, query } = useModel({ modelName, id });
  const { request: listGroupsForUser } = useService({
    method: "get",
    level: "admin",
    path: "list-groups-for-user",
    deferLoad: true,
  });

  const loadCognitoStatus = useCallback(async () => {
    setLoadingCognito(true);
    await query();
    const { email, phoneNumber, id, owners } = model;

    let cognitoResults;
    ({ data: cognitoResults } = await fetchCognitoUserByEmail(email));

    if (!cognitoResults) {
      setCognitoStatus({
        email,
        phone: phoneNumber,
        group,
        userId: id,
      });
      setLoadingCognito(false);
      setLoadedCognito(true);
      return;
    }

    const cognitoId = cognitoResults.Username;
    const groupsResults = await listGroupsForUser({ variables: { username: cognitoId } });
    const groups = [];
    groupsResults?.data?.Groups.forEach(({ GroupName }) => groups.push(GroupName));

    const sub_in_owners = (owners || []).includes(cognitoId);
    const group_in_groups = groups.includes(group);
    const { UserStatus: user_status, Username: username, UserCreateDate: created_at } = cognitoResults;

    const result = {
      user_status,
      username,
      created_at,
      model_owners: owners,
      sub_in_owners,
      group,
      groups,
      group_in_groups,
    };

    cognitoResults.Attributes.forEach((attr) => {
      result[attr.Name.replace("custom:", "")] = attr.Value;
    });

    console.log(group);
    console.log(result);

    setCognitoStatus(result);

    setLoadingCognito(false);
    setLoadedCognito(true);
  }, [model, query, group, listGroupsForUser]);

  useEffect(() => {
    if (loaded && !loadingCognito && !cognitoStatus) loadCognitoStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, model?.owners]);

  return {
    model,
    loaded: model && loaded && loadedCognito,
    loading: loading || loadingCognito,
    status: cognitoStatus,
    reload: loadCognitoStatus,
  };
};

export { useCognitoStatus };
