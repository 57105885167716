import React from "react";
import { Checkmark, User } from "@thrivelot/stories";
import { dateFns } from "@thrivelot/date";
import { RowListItem } from "@thrivelot/stories";

const RowItemCustomer = ({ customer, style = {} }) => {
  const { id, firstname, lastname, email, termsOfUse } = customer;
  const termsAt = (termsOfUse || {}).agreedAt;

  return (
    <RowListItem
      to={id}
      thumbnail={<User size={40} />}
      header={firstname && firstname.trim() !== "" ? `${firstname} ${lastname}` : email}
      details={
        termsAt && (
          <div className="italic text-sm flex items-center text-green-main">
            <div className="mr-1">{dateFns(termsAt).fromNow()}</div>
            <Checkmark size={12} color="green-main" />
          </div>
        )
      }
    />
  );
};

export { RowItemCustomer };
