import React from "react";
import { awsConfig } from "@thrivelot/aws";
import { formatLongDate } from "@thrivelot/date";
import { transformStringToTitleCase } from "@thrivelot/utils";

const CognitoStatusDetails = ({ status, modelName, model, detailsAppend = [] }) => {
  const {
    username,
    email,
    email_verified,
    phone_number,
    phone_number_verified,
    user_status,
    created_at,
    sub_in_owners,
    model_owners,
    userId,
    groups,
  } = status || {};

  const details = [
    {
      label: "modelName",
      description: "Model of user object",
      value: modelName,
    },
    {
      label: "model.id",
      description: `Model ${modelName.toLowerCase()}.id`,
      value: model.id,
    },
    {
      label: "groups",
      description: "Groups the auth belongs to",
      value: JSON.stringify(groups),
    },
    {
      label: "created_at",
      description: "Auth created_at",
      value: created_at && formatLongDate(created_at),
    },
    {
      label: "userId",
      description: "Attribute custom:userId",
      value: userId,
    },
    {
      label: "email",
      description: "Attribute email",
      value: email,
    },
    {
      label: "email_verified",
      description: "Attribute email_verified",
      value: email_verified,
    },
    {
      label: "phone_number",
      description: "Attribute phone_number",
      value: phone_number,
    },
    {
      label: "phone_number_verified",
      description: "Attribute phone_number_verified",
      value: phone_number_verified,
    },
    {
      label: "user_status",
      description: "Auth user_status",
      value: user_status && transformStringToTitleCase(user_status),
    },
    {
      label: "sub_in_model_owners",
      description: `Auth sub exists in ${modelName.toLowerCase()}.owners`,
      value: sub_in_owners ? "Yes" : "No",
    },
    {
      label: "sub_in_owners",
      description: `Model ${modelName.toLowerCase()}.owners`,
      value: JSON.stringify(model_owners),
    },
    ...detailsAppend,
  ];

  const cognitoLink = `https://${awsConfig.aws_project_region}.console.aws.amazon.com/cognito/v2/idp/user-pools/${awsConfig.aws_user_pools_id}/users/details/${username}?region=${awsConfig.aws_project_region}`;

  return (
    <div className="divide-y divide-tan-light">
      {details.map(({ label, description, value }) => (
        <div key={label} className="flex py-1">
          <div className="flex-1">{description}</div>
          <div>{value || "NONE"}</div>
        </div>
      ))}
      <div className="flex py-1">
        <div className="flex-1">Auth cognito id</div>
        <div>
          {username ? (
            <a href={cognitoLink} target="_blank" rel="noreferrer">
              {username}
            </a>
          ) : (
            "NONE"
          )}
        </div>
      </div>
    </div>
  );
};

export { CognitoStatusDetails };
