import React from "react";
import { Clock } from "@thrivelot/stories";
import { dateFns } from "@thrivelot/date";
import { Link, useLocation } from "react-router-dom";
import * as icons from "@thrivelot/stories";
import { useModel } from "@thrivelot/hooks";
import { HeaderSupplierLoader } from "./HeaderSupplierLoader";
import { DropdownLinks } from "../DropdownLinks";
import { ImageUserAvatar } from "../ImageUserAvatar";

const HeaderSupplier = ({ supplierId, navOptions }) => {
  const location = useLocation();
  const { model: supplier, loaded } = useModel({ modelName: "Supplier", id: supplierId });

  if (!loaded) return <HeaderSupplierLoader navOptions={navOptions} />;

  const { businessName, firstname, lastname } = supplier;
  let supplierName = `${firstname.trim()} ${lastname.trim()}`;

  const { updatedAt } = supplier;

  const pathMap = ({ location, to }) => to === location.pathname.split("/")[3];

  return (
    <div className="header-font">
      <div className="container mx-auto">
        <div className="pb-6 pt-2">
          <div className="flex text-mulch-light">
            <div className="w-full flex items-start flex-1 items-between">
              <div className="flex items-center mb-3">
                <ImageUserAvatar size={45} user={supplier} />
                <div className="flex flex-col ml-3">
                  <div className="font-bold">{businessName || ""}</div>
                  <div className="text-sm">{supplierName || ""}</div>
                </div>
              </div>
              <div className="text-sm flex gap-3 ml-6 mt-1">
                {updatedAt && (
                  <div className="italic text-sm flex items-center">
                    <Clock size={12} color="mulch-light" />
                    <div className="ml-1">{dateFns(updatedAt).fromNow()}</div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex items-center">
              <div className="block lg:hidden">
                <DropdownLinks options={navOptions} pathMatch={pathMap} />
              </div>
            </div>
          </div>

          <div className="hidden lg:flex justify-between pt-6">
            {navOptions.map(({ label, to, icon, color, hide }) => {
              const Icon = icons[icon];
              const selected = pathMap({ location, to });

              // Purposely not using react-router-dom NavLink here to keep the behavior consistent with the Dropdown
              return (
                <Link
                  key={to}
                  to={to}
                  className={`flex items-center text-tan-dark ${selected ? "font-bold !text-green-main" : ""}`}
                >
                  <Icon color={selected ? "green-main" : "tan-dark"} size={16} />
                  <div className="ml-1">{label}</div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export { HeaderSupplier };
