import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, EmptyState, PageContainer } from '@thrivelot/stories';
import { useModel, useModelList } from '@thrivelot/hooks';
import {
  HelpPrompt,
  RowListProjectsByStage,
  Confirm,
} from '@thrivelot/components';

const Projects = ({ sub, deleteSupplier, businessName, supplierName }) => {
  const { models: projects, loaded } = useModelList({
    modelName: 'Project',
    filter: { owners: { contains: sub } },
  });

  return (
    <PageContainer
      header="Projects"
      icon="Plan"
      loading={!loaded}
      documentTitle={loaded && (businessName || supplierName)}
      help={
        <HelpPrompt header="About your Projects page">
          This is a list view of your open and active projects on the thrive lot
          platform. You can see the stage, how many service providers are
          assigned to a project, and when it was last updated.
        </HelpPrompt>
      }
      headerActions={
        <Confirm
          prompt="Are you sure you want to delete this supplier?"
          onConfirm={deleteSupplier}
        >
          <Button
            icon="Trash"
            label="Delete Supplier"
            color="red-main"
            kind="outline"
            size="small"
          />
        </Confirm>
      }
    >
      {loaded && projects.length === 0 && (
        <EmptyState>No results for this Supplier...</EmptyState>
      )}
      {(!loaded || projects.length > 0) && (
        <RowListProjectsByStage projects={projects} loaded={loaded} />
      )}
    </PageContainer>
  );
};

const PageSupplierProjects = () => {
  const { supplierId } = useParams();
  const navigate = useNavigate();
  const {
    model: supplier,
    loaded,
    deleteModel,
  } = useModel({ modelName: 'Supplier', id: supplierId });

  if (!loaded) return null;

  const {
    businessName,
    firstname = '',
    lastname = '',
    owners,
  } = supplier || {};
  const supplierName = `${firstname} ${lastname}`;
  const sub = owners?.[0] || null;

  const deleteSupplier = () => {
    deleteModel();
    navigate('/suppliers');
  };

  if (!sub)
    return (
      <PageContainer
        header="Projects"
        icon="Plan"
        loading={!loaded}
        documentTitle={loaded && (businessName || supplierName)}
        help={
          <HelpPrompt header="About your Projects page">
            This is a list view of your open and active projects on the thrive
            lot platform. You can see the stage, how many service providers are
            assigned to a project, and when it was last updated.
          </HelpPrompt>
        }
        headerActions={
          <Confirm
            prompt="Are you sure you want to delete this supplier?"
            onConfirm={deleteSupplier}
          >
            <Button
              icon="Trash"
              label="Delete Supplier"
              color="red-main"
              kind="outline"
              size="small"
            />
          </Confirm>
        }
      >
        <EmptyState>
          Supplier owner variable has not been assigned correctly...
        </EmptyState>
      </PageContainer>
    );

  return (
    <Projects
      sub={sub}
      deleteSupplier={deleteSupplier}
      businessName={businessName}
      supplierName={supplierName}
    />
  );
};

export { PageSupplierProjects };
