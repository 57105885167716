import React from 'react';
import { useModel } from '@thrivelot/hooks';
import { CardCognitoStatus, ImageUserAvatar } from '@thrivelot/components';
import { CardLoader } from '@thrivelot/stories';

const SupplierCognitoStatus = ({ supplierId, customerId }) => {
  const { model: supplier, loaded: loadedSupplier } = useModel({
    modelName: 'Supplier',
    id: supplierId,
  });
  const {
    model: customer,
    actions: customerActions,
    updateModel: updateCustomer,
    loaded: loadedCustomer,
  } = useModel({ modelName: 'Customer', id: customerId });

  if (!loadedSupplier || !loadedCustomer) return <CardLoader />;

  const { businessName, firstname, lastname } = supplier;
  const supplierName = `${firstname.trim()} ${lastname.trim()}`;

  return (
    <CardCognitoStatus
      header="Supplier Auth Status"
      subheader={
        <div className="mt-2 flex items-center text-brown-dark gap-1">
          <ImageUserAvatar size={20} user={supplier} />
          <div className="font-bold text-sm">
            {businessName || supplierName}
          </div>
          <div className="font-normal text-sm">
            {businessName ? supplierName : null}
          </div>
        </div>
      }
      modelName="Supplier"
      id={supplierId}
      checklistAppend={[
        {
          label: 'customerViewerSet',
          description: 'Supplier has permissions to view customer',
          getStatus: ({ username }) =>
            (customer?.viewers || []).includes(username),
          onFix: ({ username }) =>
            updateCustomer(customerActions.set('viewers', [username]).result),
        },
      ]}
      detailsAppend={[
        {
          label: 'customer.viewers',
          description: 'Model customer.viewers',
          value: customer && JSON.stringify(customer.viewers),
        },
      ]}
    />
  );
};

export { SupplierCognitoStatus };
