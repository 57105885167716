import React, { useMemo, useState } from 'react';
import { EmptyState, PageContainer } from '@thrivelot/stories';
import { useModelList } from '@thrivelot/hooks';
import { HelpPrompt, RowListProjectsByStage } from '@thrivelot/components';
import { SearchBar } from './SearchBar';

const PageExploreProjects = () => {
  const { models: projects, loaded } = useModelList({
    modelName: 'Project',
    filter: { currentMilestone: { ne: 'CHURN' } },
  });
  const [supplierFilter, setSupplierFilter] = useState();

  const filteredProjects = useMemo(
    () =>
      supplierFilter?.value
        ? projects.filter(
            (project) =>
              project?.owners?.includes(supplierFilter.value) || false
          )
        : projects,
    [projects, supplierFilter?.value]
  );

  return (
    <PageContainer
      header="Projects"
      icon="Plan"
      loading={!loaded}
      documentTitle="Projects"
      help={
        <HelpPrompt header="About your Projects page">
          This is a list view of your open and active projects on the thrive lot
          platform. You can see the stage, how many service providers are
          assigned to a project, and when it was last updated.
        </HelpPrompt>
      }
    >
      <div className="my-5">
        <SearchBar
          supplierFilter={supplierFilter}
          setSupplierFilter={setSupplierFilter}
        />
      </div>
      {loaded && filteredProjects.length === 0 && (
        <EmptyState>No results for this Supplier...</EmptyState>
      )}
      {(!loaded || filteredProjects.length > 0) && (
        <RowListProjectsByStage projects={filteredProjects} loaded={loaded} />
      )}
    </PageContainer>
  );
};

export { PageExploreProjects };
