import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, PageContainer } from '@thrivelot/stories';
import { useModel } from '@thrivelot/hooks';
import {
  CardSupplierAbout,
  CardSupplierPersonal,
  CardSupplierProjectTypes,
  CardSupplierQualifications,
  CardSupplierSocials,
  Confirm,
} from '@thrivelot/components';

const PageSupplierProfile = () => {
  const navigate = useNavigate();
  const { supplierId } = useParams();
  const {
    model: supplier,
    deleteModel,
    loaded,
  } = useModel({ modelName: 'Supplier', id: supplierId });

  const deleteSupplier = () => {
    deleteModel();
    navigate('/suppliers');
  };

  const { businessName, firstname = '', lastname = '' } = supplier || {};
  const supplierName = `${firstname} ${lastname}`;

  return (
    <PageContainer
      header="Profile"
      icon="User"
      documentTitle={loaded && (businessName || supplierName)}
      headerActions={
        <Confirm
          prompt="Are you sure you want to delete this supplier?"
          onConfirm={deleteSupplier}
        >
          <Button
            icon="Trash"
            label="Delete Supplier"
            color="red-main"
            kind="outline"
            size="small"
          />
        </Confirm>
      }
    >
      <div className="columns-1 md:columns-2 lg:columns-3">
        <CardSupplierPersonal supplierId={supplierId} />
        <CardSupplierAbout supplierId={supplierId} />
        <CardSupplierSocials supplierId={supplierId} />
        <CardSupplierQualifications supplierId={supplierId} />
        <CardSupplierProjectTypes supplierId={supplierId} />
      </div>
    </PageContainer>
  );
};

export { PageSupplierProfile };
