import React from 'react';
import { useInvoiceProposalBillingSummary } from '@thrivelot/common';
import { useModal, useModel } from '@thrivelot/hooks';
import { Button, PageContainer, Checkmark, Pill } from '@thrivelot/stories';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  HelpPrompt,
  Confirm,
  FormProjectInvoiceProposal,
  LinkBack,
  InputCurrency,
} from '@thrivelot/components';
import { transformStringToTitleCase, constructUuid } from '@thrivelot/utils';
import { formatShortDateTime } from '@thrivelot/date';

const PageEditProjectInvoiceProposal = () => {
  const { projectId, invoiceProposalId } = useParams();
  const { openModal } = useModal();
  const navigate = useNavigate();
  const {
    model: project,
    actions: projectActions,
    updateModel: updateProject,
  } = useModel({ modelName: 'Project', id: projectId });
  const {
    model: invoiceProposal,
    loaded,
    deleteModel,
    actions: invoiceProposalActions,
    updateModel: updateInvoiceProposal,
  } = useModel({ modelName: 'InvoiceProposal', id: invoiceProposalId });
  const { billingSummary } =
    useInvoiceProposalBillingSummary(invoiceProposalId);

  const paidForPayments = billingSummary
    ? billingSummary.payments.filter(({ paidAt }) => !!paidAt)
    : [];

  const payouts = paidForPayments.flatMap((p) => {
    const paymentPayouts = billingSummary
      ? billingSummary.payouts.filter(
          (payout) => payout.paymentLabel === p.label
        )
      : [];
    return paymentPayouts;
  });

  const { status, name, creditItem } = invoiceProposal || {};

  return (
    <PageContainer
      icon="LineItems"
      header="Edit Order"
      preheader={<LinkBack label="Back to Orders" to=".." />}
      subheader={
        name && (
          <span className="flex gap-4">
            <span className="text-lg font-bold">
              {invoiceProposal?.name}{' '}
              <Pill
                color="brown-dark"
                icon="LineItems"
                size="sm"
                label={transformStringToTitleCase(status)}
              />
            </span>
          </span>
        )
      }
      documentTitle={loaded && project?.details?.title}
      headerActions={
        <div className="flex items-center gap-4">
          {/* <Dropdown
            align="right"
            label={
              <div className="text-blue-main flex items-center border-2 border-blue-main px-3 py-1 rounded-main">
                <Visibility size={16} color="blue-main" />
                <div className="ml-1 mr-1 font-bold text-blue-main">Preview</div>
                <AngleDown color="blue-main" size={16} styleProps="pt-1" />
              </div>
            }
            options={previewOptions}
          /> */}
          <Link to={`../${invoiceProposalId}`}>
            <Button
              label="Preview"
              size="small"
              kind="outline"
              icon="Visibility"
              color="blue-main"
            />
          </Link>
          <Button
            label="Admin Edit"
            size="small"
            kind="outline"
            icon="Edit"
            color="orange-main"
            onClick={() =>
              openModal({
                path: 'ModalAdminEditInvoiceProposal',
                invoiceProposalId,
                projectId,
              })
            }
          />
          {loaded && (
            <Confirm
              prompt="Are you sure you want to delete this order?"
              onConfirm={() => {
                deleteModel().then(() => {
                  updateProject(
                    projectActions.remove(
                      `invoiceProposalIds[value:${invoiceProposalId}]`
                    ).result
                  );
                  navigate('..');
                });
              }}
            >
              <Button
                icon="Trash"
                size="small"
                kind="outline"
                color="red-main"
                label="Delete"
              />
            </Confirm>
          )}
        </div>
      }
      help={
        <HelpPrompt header="About the Project Invoices page">
          The Order is divided into 5 sections: General Information, Billing
          Type, Work Phases, Invoice Details {'('}
          line item estimation{')'}, and the Billing Summary. At the bottom of
          the page you'll see an activity log and ability to Submit and Preview.
        </HelpPrompt>
      }
    >
      <div>
        <div className="text-lg font-bold mb-4">Admin</div>
        <div className="flex gap-4">
          <div className="flex-1">
            <div className="font-bold mb-4 text-sm">Payouts</div>
            {payouts.length === 0 && <div>No payouts...</div>}
            {payouts.length > 0 && (
              <div className="mb-8 gap-4 flex">
                {payouts.map(({ id, label, paymentLabel, paidAt }) =>
                  !paidAt ? (
                    <Button
                      key={id || label}
                      color="green-main"
                      onClick={() =>
                        openModal({
                          path: 'ModalInvoiceProposalPayoutSummary',
                          invoiceProposalId,
                          paymentLabel,
                          payoutLabel: label,
                        })
                      }
                    >
                      {transformStringToTitleCase(label)}
                    </Button>
                  ) : (
                    <div
                      key={id || label}
                      className="flex justify-center items-center rounded-main font-bold bg-green-dark py-2 px-4 text-md"
                    >
                      <Checkmark color="white-light" size={20} />
                      <div className="ml-1 text-white-light">
                        {transformStringToTitleCase(label)}
                        {paidAt && ` - Paid ${formatShortDateTime(paidAt)}`}
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
          <div className="flex-1">
            <div className="flex justify-between">
              <div className="text-brown-dark font-bold mb-2">Credit</div>
              {status === 'draft' && !creditItem?.id && (
                <Button
                  color="blue-main"
                  icon="Plus"
                  kind="prompt"
                  size="extra-small"
                  label="Add Credit"
                  onClick={() =>
                    updateInvoiceProposal(
                      invoiceProposalActions.set('creditItem', {
                        id: constructUuid(),
                        name: 'Credit',
                        creditAmount: 9900,
                        redistributeAmount: 25000,
                        direction: 'outflow',
                      }).result
                    )
                  }
                />
              )}
            </div>
            {!creditItem && <div className="italic pt-2">No credit....</div>}
            {creditItem && (
              <div key={creditItem.id} className="flex flex-col gap-4">
                <input
                  className="input flex-2 mr-4"
                  placeholder="Label for discount..."
                  disabled={status !== 'draft'}
                  value={creditItem.name || ''}
                  onChange={(e) =>
                    updateInvoiceProposal(
                      invoiceProposalActions.set(
                        `creditItem.name`,
                        e.target.value
                      ).result
                    )
                  }
                />
                <div className="flex gap-4">
                  <div className="relative flex-1">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span className="text-gray-500"> &#x24; </span>
                    </div>
                    <InputCurrency
                      className="input !rounded-none !pl-7 !text-current"
                      placeholder="Credit Amount"
                      disabled={status !== 'draft'}
                      value={creditItem?.creditAmount || ''}
                      onChange={(value) =>
                        updateInvoiceProposal(
                          invoiceProposalActions.set(
                            `creditItem.creditAmount`,
                            value
                          ).result
                        )
                      }
                    />
                  </div>
                  <div className="relative flex-1">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span className="text-gray-500"> &#x24; </span>
                    </div>
                    <InputCurrency
                      className="input !rounded-none !pl-7 !text-current"
                      placeholder="Redistribute Amount"
                      disabled={status !== 'draft'}
                      value={creditItem.redistributeAmount || ''}
                      onChange={(value) =>
                        updateInvoiceProposal(
                          invoiceProposalActions.set(
                            `creditItem.redistributeAmount`,
                            value
                          ).result
                        )
                      }
                    />
                  </div>
                  {status === 'draft' && (
                    <Confirm
                      prompt="Are you sure you want to delete the credit?"
                      onConfirm={() =>
                        updateInvoiceProposal(
                          invoiceProposalActions.set('creditItem', undefined)
                            .result
                        )
                      }
                    >
                      <Button
                        icon="Trash"
                        kind="prompt"
                        color="red-main"
                        size="small"
                      />
                    </Confirm>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-6 pt-4 border-t-2 border-tan-light" />

      <FormProjectInvoiceProposal
        projectId={projectId}
        invoiceProposalId={invoiceProposalId}
      />
    </PageContainer>
  );
};

export { PageEditProjectInvoiceProposal };
