/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useModal, useModelList } from "@thrivelot/hooks";
import { sortComplex } from "@thrivelot/utils";
import { constructSupplierDisplayName } from "@thrivelot/common";
import { Select } from "@thrivelot/styles";
import { Button } from "@thrivelot/stories";

const SearchBar = ({ supplierFilter, setSupplierFilter }) => {
  const { openModal } = useModal();
  const { models } = useModelList({ modelName: "Supplier" });
  const [sortedSuppliers, setSortedSuppliers] = useState([]);

  useEffect(() => {
    const sorted = sortComplex({
      arr: models.map((e) => ({
        label: constructSupplierDisplayName(e),
        value: e.owners[0],
      })),
      by: "label",
    });
    setSortedSuppliers(sorted);
  }, [models]);

  const options = [{ label: "No filter...", value: null }, ...sortedSuppliers];

  const handleOnChange = (event) => {
    if (event.label === "No filter...") setSupplierFilter(undefined);
    else setSupplierFilter(event);
  };

  return (
    <div className="flex">
      <div className="flex-1 mr-4">
        <Select
          options={options}
          placeholder="Filter by supplier..."
          value={supplierFilter}
          onChange={handleOnChange}
          style={{ width: "100%", marginRight: "12px" }}
        />
      </div>
      <Button
        label="Project"
        icon="Plus"
        size="small"
        kind="outline"
        color="green-main"
        onClick={() => openModal({ path: "ModalAddProject" })}
      />
    </div>
  );
};

export { SearchBar };
