import React from 'react';
import { useModel } from '@thrivelot/hooks';
import { Button, PageContainer } from '@thrivelot/stories';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Confirm,
  HelpPrompt,
  CardTermsStatus,
  CardCognitoStatus,
} from '@thrivelot/components';
import { CardHubspotId } from './CardHubspotId';

const PageSupplierAdmin = () => {
  const { supplierId } = useParams();
  const navigate = useNavigate();
  const {
    model: supplier,
    loaded,
    deleteModel,
  } = useModel({ modelName: 'Supplier', id: supplierId });

  const {
    termsOfUse,
    businessName,
    firstname = '',
    lastname = '',
  } = supplier || {};
  const termsAt = (termsOfUse || {}).agreedAt;
  const supplierName = `${firstname} ${lastname}`;

  return (
    <PageContainer
      icon="Console"
      header="Admin"
      documentTitle={loaded && (businessName || supplierName)}
      headerActions={
        loaded && (
          <Confirm
            prompt="Are you sure you want to delete this supplier?"
            onConfirm={() => {
              deleteModel();
              navigate('/suppliers');
            }}
          >
            <Button
              icon="Trash"
              size="small"
              color="red-main"
              label="Delete Supplier"
              kind="outline"
            />
          </Confirm>
        )
      }
      help={
        <HelpPrompt header="About the Supplier Admin Page">
          This section is where specific admin functions like user management
          and terms of use agreement information live.
        </HelpPrompt>
      }
    >
      <div className="columns-1 lg:columns-2">
        <CardHubspotId supplierId={supplierId} />
        <CardTermsStatus loaded={loaded} termsAt={termsAt} />
        <CardCognitoStatus
          header="Auth Status"
          modelName="Supplier"
          id={supplierId}
        />
      </div>
    </PageContainer>
  );
};

export { PageSupplierAdmin };
