import React, { useState } from 'react';
import { sortComplex } from '@thrivelot/utils';
import { useModelList } from '@thrivelot/hooks';
import { PageContainer, LeafLoader } from '@thrivelot/stories';
import { RenderSuppliers } from './RenderSuppliers';
import { SearchBar } from './SearchBar';

const PageExploreSuppliers = () => {
  const { models, loaded, loading } = useModelList({ modelName: 'Supplier' });
  const [supplierFilter, setSupplierFilter] = useState(null);
  const suppliers = sortComplex({
    arr: models,
    by: 'businessName',
    backupBy: 'firstname',
  });

  return (
    <PageContainer
      header={
        <div className="flex items-end">
          <div className="mr-5">Suppliers</div>
          {loading && <LeafLoader size="sm" classAppend="mb-1" />}
        </div>
      }
      documentTitle="Suppliers"
    >
      <div className="my-5">
        <SearchBar
          supplierFilter={supplierFilter}
          setSupplierFilter={setSupplierFilter}
          suppliers={suppliers}
        />
      </div>
      <RenderSuppliers
        supplierFilter={supplierFilter}
        suppliers={suppliers}
        isLoaded={loaded}
      />
    </PageContainer>
  );
};

export { PageExploreSuppliers };
