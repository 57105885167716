import React from "react";
import { useModel } from "@thrivelot/hooks";
import { PageContainer } from "@thrivelot/stories";
import { useParams } from "react-router-dom";
import { HelpPrompt, ViewInvoiceProposalInvoice, LinkBack } from "../..";

const PagePreviewProjectInvoiceProposalInvoice = () => {
  return null;

  // ! Delete if we decide not to split up the order views this way
  // const { projectId, invoiceProposalId } = useParams();

  // const { model: project } = useModel({ modelName: "Project", id: projectId });
  // const { model: invoiceProposal, loaded } = useModel({ modelName: "InvoiceProposal", id: invoiceProposalId });

  // return (
  //   <PageContainer
  //     icon="LineItems"
  //     header="Preview Order"
  //     preheader={<LinkBack label="Back to Edit" to={`../${invoiceProposalId}/edit`} />}
  //     subheader={invoiceProposal?.name}
  //     documentTitle={loaded && project?.details?.title}
  //     help={<HelpPrompt header="About the Project Invoices page">{/* TODO */}</HelpPrompt>}
  //   >
  //     <div className="m-auto max-w-6xl">
  //       <ViewInvoiceProposalInvoice projectId={projectId} invoiceProposalId={invoiceProposalId} />
  //     </div>
  //   </PageContainer>
  // );
};

export { PagePreviewProjectInvoiceProposalInvoice };
