import { useEffect, useMemo, useState } from 'react';
import { useModel } from '@thrivelot/hooks';
import { ModelKit } from '@thrivelot/model-kit';

const useInvoiceProposalAndBillingSummary = (invoiceProposalId) => {
  const {
    model: invoiceProposal,
    actions,
    loaded,
  } = useModel({ modelName: 'InvoiceProposal', id: invoiceProposalId });
  const [bundles, setBundles] = useState();

  const billing = useMemo(
    () => bundles && actions.getBillingSummary(bundles),
    [bundles, actions]
  );

  useEffect(() => {
    if (!loaded) return;

    let didCancel;

    const fetchBundles = () =>
      Promise.all(
        invoiceProposal.bundleIds.map((bundleId) => {
          const bundleKit = new ModelKit({ modelName: 'Bundle', id: bundleId });
          return bundleKit.query();
        })
      );

    (async () => {
      const fetchedBundles = await fetchBundles();
      if (!didCancel) setBundles(fetchedBundles);
    })();

    // eslint-disable-next-line consistent-return
    return () => {
      didCancel = true;
    };
  }, [invoiceProposal?.bundleIds, loaded]);

  return { invoiceProposal, actions, billing, loaded };
};

export { useInvoiceProposalAndBillingSummary };
