import React from 'react';
import { Card, Checkmark, Info, CardLoader } from '@thrivelot/stories';
import { dateFns } from '@thrivelot/date';

const CardTermsStatus = ({ termsAt, loaded }) => {
  if (!loaded) return <CardLoader showText />;

  return (
    <Card classAppend="mb-4">
      {termsAt ? (
        <div className="italic text-sm flex items-center text-green-main">
          <Checkmark size={15} color="green-main" />
          <div className="ml-1">
            Accepted Terms on{' '}
            <span className="font-bold">
              {dateFns(termsAt).format('MMM DD, YYYY')}
            </span>
          </div>
        </div>
      ) : (
        <div className="italic flex text-sm items-center text-brown-dark">
          <Info size={15} color="red-main" />
          <div className="ml-1">
            Supplier has not yet accepted the Terms of Service
          </div>
        </div>
      )}
    </Card>
  );
};

export { CardTermsStatus };
