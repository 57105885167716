import React from 'react';
import { RowItemLoader, RowList } from '@thrivelot/stories';
import { RowItemCustomer } from '@thrivelot/components';

const RenderCustomers = ({ customerFilter, customers, isLoaded }) => {
  const renderCustomers = () => {
    const filteredCustomers = customerFilter
      ? customers.filter((customer) => customer.id === customerFilter.value)
      : customers;

    return filteredCustomers.map((customer) => (
      <RowItemCustomer key={customer.id} customer={customer} />
    ));
  };

  if (!isLoaded) return <RowItemLoader showText />;

  return <RowList>{renderCustomers()}</RowList>;
};

export { RenderCustomers };
