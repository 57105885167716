import React, { useEffect, useState } from 'react';
import Image from 'react-graceful-image';
import { PlantKit } from '@thrivelot/plant-kit';
import { Leaf } from '@thrivelot/stories';

export const PlantPic = ({ plant }) => {
  const [src, setSrc] = useState(null);

  const { commonName } = plant;

  useEffect(() => {
    if (!plant?.images?.length) return;

    let didCancel;

    const fetchImage = async () => {
      const plantKit = new PlantKit({ model: plant });
      const imgs = await plantKit.listImages();
      if (!imgs?.length) {
        if (!didCancel) setSrc(null);
        return;
      }

      const [img] = imgs;
      if (!didCancel) setSrc(img.url);
    };

    fetchImage();

    // eslint-disable-next-line consistent-return
    return () => {
      didCancel = true;
    };
  }, [plant]);

  return src ? (
    <Image
      src={src}
      alt={commonName}
      className="h-10 w-10 rounded object-center object-cover"
    />
  ) : (
    <div className="flex h-10 w-10 items-center justify-center">
      <Leaf size={24} color="green-main" />
    </div>
  );
};
