import React from 'react';
import { useModel } from '@thrivelot/hooks';
import { Button, PageContainer } from '@thrivelot/stories';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CardAboutCustomer,
  CardProjectCustomerInfo,
  CardProjectFeatures,
  CardProjectPriorities,
  CardProjectStyles,
  CardProjectInfo,
  CardProjectServiceProviders,
  CardProjectCalendar,
  // CardProjectPlantPalette,
  CardProjectSiteAssessmentAvailability,
  Confirm,
  HelpPrompt,
  // CardProjectProposalLink,
} from '@thrivelot/components';

const PageProjectDetails = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const {
    model: project,
    loaded,
    deleteModel,
  } = useModel({ modelName: 'Project', id: projectId });

  return (
    <PageContainer
      icon="Book"
      header="Details"
      documentTitle={loaded && project.details.title}
      headerActions={
        loaded && (
          <Confirm
            prompt="Are you sure you want to delete this project?"
            onConfirm={() => {
              deleteModel();
              navigate('/projects');
            }}
          >
            <Button
              icon="Trash"
              size="small"
              color="red-main"
              label="Delete Project"
              kind="outline"
            />
          </Confirm>
        )
      }
      help={
        <HelpPrompt header="About the Project Details Page">
          This section is the primary dashboard for each project. Customer
          contact information, vision, budget, and priorities can be found here.
          It also has a plant palette, which is a summary of all the plants on
          the property, as well as styles and features as entered by the
          customer when they were onboarded.
        </HelpPrompt>
      }
    >
      <div className="columns-1 lg:columns-2">
        <CardProjectSiteAssessmentAvailability projectId={projectId} />
        <CardProjectServiceProviders
          projectId={projectId}
          editable
          showProfileLinks
        />
        {/* <CardProjectProposalLink projectId={projectId} /> */}
        <CardProjectInfo projectId={projectId} editable />
        <CardProjectCustomerInfo projectId={projectId} editable />
        <CardProjectCalendar projectId={projectId} />
        <CardAboutCustomer projectId={projectId} />
        <CardProjectPriorities projectId={projectId} editable />
        <CardProjectFeatures projectId={projectId} editable />
        <CardProjectStyles projectId={projectId} editable />

        {/* Commenting this out because the plant palette needs to be refactored to pull the plants from the Bundles */}
        {/* <CardProjectPlantPalette projectId={projectId} /> */}
      </div>
    </PageContainer>
  );
};

export { PageProjectDetails };
