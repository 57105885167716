import React from 'react';
import { sort } from '@thrivelot/utils';
import { constructSupplierDisplayName } from '@thrivelot/common';
import { Select } from '@thrivelot/styles';
import { Button } from '@thrivelot/stories';
import { useModal } from '@thrivelot/hooks';

const SearchBar = ({ supplierFilter, setSupplierFilter, suppliers }) => {
  const { openModal } = useModal();

  const supplierOptions = sort(
    suppliers.map((e) => ({
      label: constructSupplierDisplayName(e),
      value: e.id,
    })),
    'label'
  );

  const options = [{ label: 'No filter...', value: null }, ...supplierOptions];

  const handleOnChange = (e) => {
    if (e.label === 'No filter...') setSupplierFilter(undefined);
    else setSupplierFilter(e);
  };

  return (
    <div className="flex">
      <Select
        options={options}
        placeholder="Filter by supplier..."
        value={supplierFilter}
        onChange={handleOnChange}
        style={{
          width: '100%',
          marginRight: '12px',
        }}
      />
      <Button
        icon="Plus"
        label="Supplier"
        size="small"
        kind="outline"
        color="green-main"
        onClick={() => openModal({ path: 'ModalAddSupplier' })}
      />
    </div>
  );
};

export { SearchBar };
