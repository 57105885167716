import { fetchOrCreateCognitoUser, useCognitoStatus } from "@thrivelot/common";
import { Button, Card } from "@thrivelot/stories";
import { Refresh, Visibility } from "@thrivelot/stories";
import { CardLoader } from "@thrivelot/stories";
import { Spinner } from "@thrivelot/stories";
import React, { useCallback, useState } from "react";
import { CognitoStatusChecklist } from "./CognitoStatusChecklist";
import { CognitoStatusDetails } from "./CognitoStatusDetails";

const CardCognitoStatus = ({ header, subheader, modelName, id, checklistAppend, detailsAppend }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [creating, setCreating] = useState(false);
  const { model, loaded, status, reload, loading } = useCognitoStatus({ modelName, id });

  const handleCreateUser = useCallback(() => {
    setCreating(true);
    fetchOrCreateCognitoUser(status)
      .then((results) => setCreating(false))
      .then(reload)
      .then((errors) => setCreating(false));
  }, [reload, status]);

  if (!loaded) return <CardLoader showText />;

  return (
    <Card
      header={header || "Auth Status"}
      subheader={subheader}
      classAppend="mb-4 text-brown-dark"
      headerActions={
        <div className="flex items-center gap-4">
          {loading ? (
            <Spinner size="small" />
          ) : (
            <Refresh color="blue-main" styleProps="hover:cursor-pointer" size="20" onClick={() => reload()} />
          )}
          <Visibility
            color="yellow-dark"
            styleProps="hover:cursor-pointer"
            size="20"
            onClick={() => setShowDetails(!showDetails)}
          />
        </div>
      }
    >
      {!status?.username && (
        <div className="flex flex-col gap-4">
          <div>This user does not yet have a login.</div>
          <div>
            <div>Email: {status.email}</div>
            <div>Phone: {status.phone}</div>
            <div>Group: {status.group}</div>
            <div>UserId: {status.userId}</div>
          </div>
          <Button
            loading={creating}
            block
            color="green-main"
            kind="outline"
            label="Create Login"
            onClick={() => handleCreateUser()}
          />
        </div>
      )}
      {status?.username && showDetails && (
        <CognitoStatusDetails status={status} modelName={modelName} model={model} detailsAppend={detailsAppend} />
      )}
      {status?.username && (
        <CognitoStatusChecklist
          status={status}
          modelName={modelName}
          model={model}
          reload={reload}
          checklistAppend={checklistAppend}
        />
      )}
    </Card>
  );
};

export { CardCognitoStatus };
