import React from 'react';

const SearchBar = ({ searchText, setSearchText }) => (
  <div className="my-5 relative">
    <input
      className="input"
      placeholder="Search orders"
      value={searchText}
      onChange={(e) => setSearchText(e.target.value)}
    />
  </div>
);

export { SearchBar };
