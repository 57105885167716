import React, { useCallback, useState } from 'react';
import { Button } from '@thrivelot/stories';
import { Checkmark } from '@thrivelot/stories';
import { transformStringToTitleCase } from '@thrivelot/utils';
import { ModelKit } from '@thrivelot/model-kit';
import { useService } from '@thrivelot/hooks';
import { LeafLoader } from '@thrivelot/stories';

const Affirmative = () => (
  <div className="bg-green-main py-0.5 px-2 rounded-main text-sm text-white-light flex items-center gap-1">
    <Checkmark color="white-light" size={12} />
    Yes
  </div>
);
const Negative = () => <div className="text-red-main">No</div>;
const Status = ({ getStatus }) => {
  const status = getStatus();

  if (status === true) return <Affirmative />;
  if (status === 'loading') return <LeafLoader size="xs" />;
  return <Negative />;
};

const CognitoStatusChecklist = ({
  status,
  modelName,
  model,
  reload,
  checklistAppend = [],
}) => {
  const { request: updateUserAttributes } = useService({
    method: 'post',
    level: 'admin',
    path: 'update-user-attributes',
    deferLoad: true,
  });

  const { request: resetUserPassword } = useService({
    method: 'post',
    level: 'admin',
    path: 'reset-user-password',
    deferLoad: true,
  });

  const [loading, setLoading] = useState({});
  const { group, user_status } = status || {};

  const fixWrapper = useCallback(
    (onFix, label, status, model) => {
      setLoading((prevLoading) => ({ ...prevLoading, [label]: true }));
      (onFix(status, model) || Promise.resolve()).then(() => {
        setLoading((prevLoading) => ({ ...prevLoading, [label]: undefined }));
        reload();
      });
    },
    [reload]
  );

  const handleResetUserPassword = useCallback(
    ({ username }) =>
      resetUserPassword({ variables: { username, groupname: group } }),
    [resetUserPassword, group]
  );

  const checklist = [
    {
      label: 'userIdProp',
      description: 'Cognito userId matches model.id',
      getStatus: ({ userId }, { id }) => id === userId,
      onFix: ({ username }, { id }) =>
        updateUserAttributes({
          variables: { username, attributes: { 'custom:userId': id } },
        }),
    },
    {
      label: 'subInUserOwners',
      description: 'User permissions for user object',
      getStatus: ({ sub_in_owners }) => sub_in_owners,
      onFix: ({ username }, { id }) => {
        const userKit = new ModelKit({ modelName, model });
        return userKit.update({ updated: { owners: [username] } });
      },
    },
    {
      label: 'emailMatches',
      description: 'Model email matches auth email',
      getStatus: ({ email: authEmail }, { email: modelEmail }) =>
        authEmail === modelEmail,
      onFix: ({ email: authEmail, username }, { email: modelEmail }) => {
        if (!authEmail && modelEmail)
          return updateUserAttributes({
            variables: {
              username,
              attributes: { email: modelEmail, email_verified: 'True' },
            },
          });

        if ((authEmail && !modelEmail) || authEmail !== modelEmail) {
          const userKit = new ModelKit({ modelName, id: model.id });
          return userKit.update({ updated: { email: authEmail } });
        }

        return Promise.resolve();
      },
    },
    {
      label: 'emailVerified',
      description: `Email is verified (${status.email})`,
      getStatus: ({ email_verified }) =>
        email_verified === 'True' || email_verified === 'true',
      onFix: ({ username }) =>
        updateUserAttributes({
          variables: { username, attributes: { email_verified: 'True' } },
        }),
    },
    {
      label: 'phoneMatches',
      description: 'Model phone number matches auth phone number',
      getStatus: ({ phone_number: authPhone }, { phoneNumber: modelPhone }) =>
        authPhone === modelPhone,
      onFix: (
        { phone_number: authPhone, username },
        { phoneNumber: modelPhone }
      ) => {
        if (!authPhone && modelPhone)
          return updateUserAttributes({
            variables: {
              username,
              attributes: {
                phone_number: modelPhone,
                phone_number_verified: 'True',
              },
            },
          });

        if ((authPhone && !modelPhone) || authPhone !== modelPhone) {
          const userKit = new ModelKit({ modelName, model });
          return userKit.update({ updated: { phoneNumber: authPhone } });
        }

        return Promise.resolve();
      },
    },
    {
      label: 'phoneVerified',
      description: `Phone number is verified (${status.phone_number})`,
      getStatus: ({ phone_number_verified }) =>
        phone_number_verified === 'True' || phone_number_verified === 'true',
      onFix: ({ username }) =>
        updateUserAttributes({
          variables: {
            username,
            attributes: { phone_number_verified: 'True' },
          },
        }),
    },
    {
      label: 'correctGroup',
      description: `Auth belongs to ${group} group`,
      getStatus: ({ group_in_groups }) => group_in_groups,
    },
    ...checklistAppend,
  ];

  return (
    <div className="divide-y divide-tan-light">
      {checklist.map(({ label, description, getStatus, onFix }) => (
        <div key={label} className="flex py-1 gap-2">
          <div className="flex-1">{description}</div>
          <div>
            <Status getStatus={() => getStatus(status, model)} />
          </div>
          {getStatus(status, model) === false && onFix && (
            <Button
              loading={loading[label]}
              size="extra-small"
              kind="outline"
              label="Fix"
              color="red-main"
              onClick={() => fixWrapper(onFix, label, status, model)}
            />
          )}
        </div>
      ))}

      <div className="flex py-1 gap-2">
        <div className="flex-1">Auth status</div>
        <div className="text-blue-main">
          {transformStringToTitleCase(user_status)}
        </div>
        <Button
          loading={loading.resetPassword}
          size="extra-small"
          kind="outline"
          label="Reset Password"
          color="blue-main"
          onClick={() =>
            fixWrapper(handleResetUserPassword, 'resetPassword', status, model)
          }
        />
      </div>
      {user_status === 'FORCE_CHANGE_PASSWORD' && (
        <div className="py-1 italic">
          A status of "Force Change Password" usually means the password is the
          standard password for new users. Clicking the "Reset Password" button
          will reset the password to our standard password for new users
        </div>
      )}
    </div>
  );
};

export { CognitoStatusChecklist };
