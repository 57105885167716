import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useModel } from '@thrivelot/hooks';
import { Button, PageContainer } from '@thrivelot/stories';
import { CardCustomerPersonal } from '@thrivelot/components';

const PageCustomerDetails = () => {
  const navigate = useNavigate();

  const { customerId } = useParams();
  const {
    model: customer,
    deleteModel,
    loaded,
  } = useModel({ modelName: 'Customer', id: customerId });
  const { showNotification } = window;

  const deleteCustomer = useCallback(() => {
    deleteModel();
    showNotification({
      type: 'info',
      title: 'Deleting supplier',
      body: 'This can take a few seconds.',
    });
    navigate('/customers');
  }, [deleteModel, navigate, showNotification]);

  return (
    <PageContainer
      header={
        loaded ? `${customer.firstname} ${customer.lastname}` : 'Loading...'
      }
      documentTitle={loaded && `${customer.firstname} ${customer.lastname}`}
      headerActions={
        <Button
          icon="Trash"
          label="Delete"
          color="red-main"
          kind="outline"
          size="small"
          onClick={deleteCustomer}
        />
      }
    >
      <CardCustomerPersonal customerId={customerId} />
    </PageContainer>
  );
};

export { PageCustomerDetails };
