import { useModel } from "@thrivelot/hooks";
import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { HeaderSupplier, PageUnchartedLands } from "..";

const LayoutSupplier = ({ navConfig }) => {
  const { supplierNavOptions: options } = navConfig;
  const { supplierId } = useParams();
  const { loaded, model: supplier } = useModel({ modelName: "Supplier", id: supplierId });

  if (loaded && !supplier) return <PageUnchartedLands />;

  return (
    <>
      <HeaderSupplier supplierId={supplierId} navOptions={options} />
      <Outlet />
    </>
  );
};

export { LayoutSupplier };
