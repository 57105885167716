import React from "react";

const HeaderSupplierLoader = ({ navOptions }) => (
  <div className="pb-6 pt-2">
    <div className="w-full">
      <div className="animate-pulse flex space-x-4">
        <div className="rounded-full bg-tan-light h-10 w-10"></div>
        <div className="flex-1 py-1 flex flex-col items-between text-brown-light">
          <div className="text-brown-light mb-1">Loading...</div>
          <div className="h-2 w-28 bg-tan-light rounded-full"></div>
        </div>
        <div className="py-1 flex flex-col justify-around">
          <div className="h-2 w-24 bg-tan-light rounded-full"></div>
        </div>
      </div>
    </div>
    <div className="hidden lg:flex animate-pulse break-inside-avoid justify-between pt-6">
      {navOptions.map(({ to }) => (
        <div key={to} className="h-2 w-20 my-2 bg-tan-light rounded-full"></div>
      ))}
    </div>
  </div>
);

export { HeaderSupplierLoader };
