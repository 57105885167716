import React from 'react';
import { Card, CardLoader } from '@thrivelot/stories';
import { useModel } from '@thrivelot/hooks';
import { InputPhoneNumber } from '@thrivelot/styles';

const CardCustomerPersonal = ({ customerId }) => {
  const {
    model: customer,
    updateModel: updateCustomer,
    loaded,
  } = useModel({ modelName: 'Customer', id: customerId });

  if (!loaded) return <CardLoader />;

  return (
    <Card>
      <div className="md:flex md:gap-5">
        <div className="mb-4 md:mb-0 md:flex-1">
          <div className="text-green-dark text-sm font-bold">First Name</div>
          <input
            className="input"
            title="First name"
            placeholder="Your first name"
            value={customer.firstname || ''}
            onChange={(e) => updateCustomer({ firstname: e.target.value })}
          />
        </div>
        <div className="mb-4 md:flex-1">
          <div className="text-green-dark text-sm font-bold">Last Name</div>
          <input
            className="input"
            title="Last name"
            placeholder="Your last name"
            value={customer.lastname || ''}
            onChange={(e) => updateCustomer({ lastname: e.target.value })}
          />
        </div>
      </div>

      <div className="md:flex md:gap-5">
        <div className="mb-4 md:flex-1">
          <div className="text-green-dark text-sm font-bold">Email</div>
          <input
            className="input"
            title="Email"
            placeholder="Email"
            value={customer.email || ''}
            onChange={(e) => updateCustomer({ email: e.target.value })}
          />
        </div>
        <div className="mb-4 md:flex-1">
          <div className="text-green-dark text-sm font-bold">Phone</div>
          <InputPhoneNumber
            placeholder="Phone number"
            className="input"
            value={customer.phoneNumber || ''}
            onChange={(value) => updateCustomer({ phoneNumber: value })}
          />
        </div>
      </div>
    </Card>
  );
};

export { CardCustomerPersonal };
