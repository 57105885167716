import React from 'react';
import { Button, Card, CardLoader } from '@thrivelot/stories';
import { useModel } from '@thrivelot/hooks';

const CustomerContactHubspotId = ({ customerId }) => {
  const {
    model: customer,
    loaded,
    updateModel,
    actions,
  } = useModel({ modelName: 'Customer', id: customerId });

  if (!loaded) return null;

  const { hubspotId } = customer;

  return (
    <>
      <div className="text-brown-dark mb-1 text-sm">
        Hubspot Customer Contact ID
      </div>
      <div className="flex gap-4">
        <input
          className="input !flex-1"
          placeholder="Add the hubspot customer contact id..."
          value={hubspotId || ''}
          onChange={(e) =>
            updateModel(actions.set('hubspotId', e.target.value).result)
          }
        />
        {hubspotId && (
          <a
            className="flex-1"
            href={`https://app.hubspot.com/contacts/6792408/contact/${hubspotId}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button icon="Expand" color="mulch-light" block kind="outline">
              Contact
            </Button>
          </a>
        )}
      </div>
    </>
  );
};

const CardProjectAdmin = ({ projectId, editable }) => {
  const {
    model: project,
    loaded,
    updateModel,
    actions,
  } = useModel({ modelName: 'Project', id: projectId });

  if (!loaded) return <CardLoader showText />;

  const { hubspotId } = project;

  return (
    <Card header="Admin" classAppend="mb-4 flex flex-col gap-4">
      <div>
        <div className="flex flex-col">
          <div className="text-brown-dark mb-1 text-sm">Hubspot Deal ID</div>
          <div className="mb-4 flex gap-4">
            <input
              className="input !flex-1"
              placeholder="Add the hubspot deal id..."
              value={hubspotId || ''}
              onChange={(e) =>
                updateModel(actions.set('hubspotId', e.target.value).result)
              }
            />
            {hubspotId && (
              <a
                className="flex-1"
                href={`https://app.hubspot.com/contacts/6792408/deal/${hubspotId}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button icon="Expand" color="mulch-light" block kind="outline">
                  Deal
                </Button>
              </a>
            )}
          </div>
          <CustomerContactHubspotId
            customerId={project?.customer?.id || project?.customerId}
          />
        </div>
      </div>
    </Card>
  );
};

export { CardProjectAdmin };
