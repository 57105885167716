import React from 'react';
import { PageContainer } from '@thrivelot/stories';
import { CardAccount } from '@thrivelot/components';

const PageSettings = () => (
  <PageContainer
    header="Settings"
    documentTitle="Settings"
    headerActions={
      <div className="text-brown-dark font-bold text-sm">{`App Version: ${window.appConfig.version}`}</div>
    }
  >
    <div className="columns-1 sm:columns-2 lg:columns-3">
      <CardAccount />
    </div>
  </PageContainer>
);

export { PageSettings };
