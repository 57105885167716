const calculateOrder = (a, b) => {
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};

const compare = (a, b, by, backupBy) => {
  if (!by && !backupBy) return calculateOrder(a, b);
  if (!backupBy) return calculateOrder(a[by] || "", b[by] || "");
  return calculateOrder(a[by] || a[backupBy] || "", b[by] || b[backupBy] || "");
};

const sort = (arr, by, backupBy) => arr.sort((a, b) => compare(a, b, by, backupBy));

export { sort };
