import React from 'react';
import { Checkmark, RowListItem } from '@thrivelot/stories';
import { dateFns } from '@thrivelot/date';
import SupplierPic from './SupplierPic';

const RowItemSupplier = ({ supplier, style = {} }) => {
  const { id, termsOfUse, businessName, firstname, lastname } = supplier;

  const termsAt = (termsOfUse || {}).agreedAt;
  const supplierName = `${firstname.trim()} ${lastname.trim()}`;

  return (
    <RowListItem
      to={id}
      thumbnail={<SupplierPic supplier={supplier} />}
      header={businessName || supplierName}
      subheader={businessName ? supplierName : null}
      details={
        termsAt && (
          <div className="italic text-sm flex items-center text-green-main">
            <div className="mr-1">{dateFns(termsAt).fromNow()}</div>
            <Checkmark size={12} color="green-main" />
          </div>
        )
      }
    />
  );
};

export { RowItemSupplier };
