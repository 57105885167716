import React from 'react';
import { Button, Card, CardLoader } from '@thrivelot/stories';
import { useModel } from '@thrivelot/hooks';

const CardHubspotId = ({ supplierId }) => {
  const {
    model: supplier,
    loaded,
    updateModel,
    actions,
  } = useModel({ modelName: 'Supplier', id: supplierId });

  if (!loaded) return <CardLoader showText />;

  const { hubspotId } = supplier;

  return (
    <Card header="Admin" classAppend="mb-4 flex flex-col gap-4">
      <div>
        <div className="flex flex-col">
          <div className="text-brown-dark mb-1 text-sm">Hubspot Contact ID</div>
          <div className="mb-4 flex gap-4">
            <input
              className="input !flex-1"
              placeholder="Add the hubspot contact id..."
              value={hubspotId || ''}
              onChange={(e) =>
                updateModel(actions.set('hubspotId', e.target.value).result)
              }
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export { CardHubspotId };
