import React, { useState, useEffect } from 'react';
import { useModel } from '@thrivelot/hooks';
import { Button, PageContainer } from '@thrivelot/stories';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Confirm,
  HelpPrompt,
  CardProjectAdmin,
  CardCognitoStatus,
  CardProjectUseInvoiceProposals,
} from '@thrivelot/components';
import { ModelKit } from '@thrivelot/model-kit';
import { SupplierCognitoStatus } from './SupplierCognitoStatus';

const PageProjectAdmin = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const {
    model: project,
    actions,
    updateModel,
    loaded,
    deleteModel,
  } = useModel({ modelName: 'Project', id: projectId });

  // This is to load the suppliers for the Cognito user status
  const [suppliers, setSuppliers] = useState();
  useEffect(() => {
    if (!project?.owners) return;

    Promise.all(
      project.owners.map((sub) => {
        const supplierKit = new ModelKit({ modelName: 'Supplier' });
        return supplierKit.scan({ filter: { owners: { contains: sub } } });
      })
    ).then((ownerSuppliers) => setSuppliers(ownerSuppliers.flat()));
  }, [project?.owners]);

  const customerId = project?.customer?.id || project?.customerId;

  return (
    <PageContainer
      icon="Console"
      header="Admin"
      documentTitle={loaded && project.details.title}
      headerActions={
        loaded && (
          <Confirm
            prompt="Are you sure you want to delete this project?"
            onConfirm={() => {
              deleteModel();
              navigate('/projects');
            }}
          >
            <Button
              icon="Trash"
              size="small"
              color="red-main"
              label="Delete Project"
              kind="outline"
            />
          </Confirm>
        )
      }
      help={
        <HelpPrompt header="About the Project Admin Page">
          This section is where specific admin functions like user management
          and CRM connections live.
        </HelpPrompt>
      }
    >
      <div className="columns-1 lg:columns-2">
        <CardProjectUseInvoiceProposals projectId={projectId} />
        <CardProjectAdmin projectId={projectId} />
        {customerId && (
          <CardCognitoStatus
            header="Customer Auth Status"
            modelName="Customer"
            id={customerId}
            checklistAppend={[
              {
                label: 'projectViewerSet',
                description: 'Customer has permissions to view project',
                getStatus: ({ username }) =>
                  (project?.viewers || []).includes(username),
                onFix: ({ username }) =>
                  updateModel(actions.set('viewers', [username]).result),
              },
              {
                label: 'projectInCustomerProjectIds',
                description: 'Project is linked to customer model',
                // getStatus: (_, { projects }) =>
                //   (projects?.items || [])
                //     .map(({ id }) => id)
                //     .includes(projectId),
                getStatus: (_, { id }) => project?.customerId === id,
                onFix: async (_, { id }) => {
                  // const customerKit = new ModelKit({
                  //   modelName: 'Customer',
                  //   id,
                  // });

                  // const customer = await customerKit.query();

                  // const projectIds = [
                  //   ...(customer.projectIds || []),
                  //   projectId,
                  // ].filter(
                  //   (value, index, array) => array.indexOf(value) === index
                  // ); // remove duplicates

                  // return customerKit.update({
                  //   updated: { projectIds },
                  // });
                  updateModel({ customerId: id });
                },
              },
              {
                label: 'customerCanViewSuppliers',
                description: 'Customer can view suppliers',
                getStatus: ({ username }) => {
                  if (!suppliers) return 'loading';
                  return (
                    suppliers.filter((supplier) =>
                      supplier?.viewers?.includes(username)
                    ).length !== 0
                  );
                },
                onFix: ({ username }) =>
                  Promise.all(
                    suppliers.map((supplier) => {
                      if (!supplier.viewers.includes(username)) {
                        const supplierKit = new ModelKit({
                          modelName: 'Supplier',
                          model: supplier,
                        });
                        return supplierKit.update({
                          updated: {
                            viewers: [...(supplier.viewers || []), username],
                          },
                        });
                      }
                      return Promise.resolve();
                    })
                  ),
              },
            ]}
            detailsAppend={[
              {
                label: 'project.viewers',
                description: 'Model project.viewers',
                value: project && JSON.stringify(project.viewers),
              },
            ]}
          />
        )}
        {suppliers?.length
          ? suppliers.map((supplier) => (
              <SupplierCognitoStatus
                key={supplier.id}
                supplierId={supplier.id}
                customerId={customerId}
              />
            ))
          : null}
      </div>
    </PageContainer>
  );
};

export { PageProjectAdmin };
