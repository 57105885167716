import React from 'react';
import { useModel, useCatalog } from '@thrivelot/hooks';
import { PageContainer } from '@thrivelot/stories';
import { useParams } from 'react-router-dom';
import { HelpPrompt, CatalogImport, LinkBack } from '@thrivelot/components';

export const PageSupplierCatalogImport = () => {
  const { supplierId } = useParams();

  const { model: supplier, loaded: supplierLoaded } = useModel({
    modelName: 'Supplier',
    id: supplierId,
  });

  const { loaded, catalog, ownerId } = useCatalog({
    supplierId,
  });

  const { firstname = '', lastname = '' } = supplier || {};
  const supplierName = `${firstname} ${lastname}`;

  if (!loaded) return null;
  return (
    <PageContainer
      icon="Catalog"
      header="Catalog Import Line Item"
      documentTitle={`${supplierName} Catalog Import`}
      preheader={<LinkBack label="Back to Catalog" to=".." />}
      loading={!loaded || !supplierLoaded}
      help={
        <HelpPrompt header="About the Catalog Import Page">
          Choose from your previously added items to quickly and easily import
          them into your official catalog.
        </HelpPrompt>
      }
    >
      {loaded && catalog && (
        <CatalogImport catalogId={catalog.id} ownerId={ownerId} />
      )}
    </PageContainer>
  );
};
