import React from 'react';
import { sortComplex } from '@thrivelot/utils';
import { Select } from '@thrivelot/styles';

const SearchBar = ({ customerFilter, setCustomerFilter, customers }) => {
  const customerOptions = sortComplex({
    arr: customers.map((e) => ({
      label:
        e.firstname && e.firstname.trim() !== ''
          ? `${e.firstname} ${e.lastname}`
          : e.email,
      value: e.id,
    })),
    by: 'label',
  });

  const options = [{ label: 'No filter...', value: null }, ...customerOptions];

  const handleOnChange = (e) => {
    if (e.label === 'No filter...') setCustomerFilter(null);
    else setCustomerFilter(e);
  };

  return (
    <div className="flex">
      <Select
        options={options}
        placeholder="Filter by customer..."
        value={customerFilter}
        onChange={handleOnChange}
        style={{
          width: '100%',
        }}
      />
    </div>
  );
};

export { SearchBar };
