import React, { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useModelList } from '@thrivelot/hooks';
import { RowItemLoader, EmptyState, RowList } from '@thrivelot/stories';
import { isEmpty } from '@thrivelot/utils';
import { RowItemOrder } from './RowItemOrder';

const Orders = ({ searchText }) => {
  const { models: invoiceProposals, loaded } = useModelList({
    modelName: 'InvoiceProposal',
  });
  const { pathname } = useLocation();
  const [slugs, setSlugs] = useState({});

  const filteredOrders = useMemo(() => {
    if (isEmpty(searchText) || isEmpty(invoiceProposals))
      return invoiceProposals;

    const ids = Object.keys(slugs).filter((id) =>
      slugs[id].includes(searchText.toLowerCase())
    );
    return invoiceProposals.filter(
      (invoiceProposal) =>
        ids.includes(invoiceProposal.id) &&
        invoiceProposal.name !== 'Site Visit'
    );
  }, [searchText, invoiceProposals, slugs]);

  const updateSlugs = useCallback((id, slug) => {
    setSlugs((prev) => ({ ...prev, [id]: slug }));
  }, []);

  if (!loaded) return <RowItemLoader showText />;

  const pathArray = pathname.split('/');
  const status = pathArray[pathArray.length - 1];

  if (isEmpty(filteredOrders))
    return (
      <EmptyState>
        No orders to display
        {isEmpty(searchText) ? (
          ''
        ) : (
          <>
            {' '}
            for <span className="font-bold text-mulch-dark">{searchText}</span>
          </>
        )}
        ...
      </EmptyState>
    );

  return (
    <RowList>
      {filteredOrders.map(({ id }) => (
        <RowItemOrder
          key={id}
          invoiceProposalId={id}
          to={id}
          status={status}
          updateSlugs={updateSlugs}
        />
      ))}
    </RowList>
  );
};

export { Orders };
