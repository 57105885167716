const handleShowSupplierBusinessName = (supplier): string | undefined => {
  if (
    supplier.businessName &&
    supplier.businessName.trim() !== '' &&
    supplier.businessName.trim() !== ''
  ) {
    return `${supplier.firstname.trim()} ${supplier.lastname.trim()}`;
  }

  return undefined;
};

export { handleShowSupplierBusinessName };
