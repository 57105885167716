import React, { useState } from 'react';
import { DropdownLinks } from '@thrivelot/components';
import { PageContainer } from '@thrivelot/stories';
import { Orders } from './Orders';
import { SearchBar } from './SearchBar';

const options = [
  {
    label: 'All',
    icon: 'Cost',
    to: '/orders',
  },
  {
    label: 'Unpaid',
    icon: 'Cost',
    to: '/orders/unpaid',
  },
  {
    label: 'Partially Paid',
    icon: 'Cost',
    to: '/orders/partially-paid',
  },
  {
    label: 'Needs Verification',
    icon: 'Cost',
    to: '/orders/needs-verification',
  },
  {
    label: 'Paid',
    icon: 'Cost',
    to: '/orders/paid',
  },
];

const PageExploreOrders = () => {
  const [searchText, setSearchText] = useState('');

  return (
    <PageContainer
      header="Orders"
      documentTitle="Orders"
      dropdown={
        <DropdownLinks
          color="brown-dark"
          options={options}
          pathMatch={({ location, to }) => to === location.pathname}
          classAppend="!px-3 !py-0.5 text-sm !border-none pt-1"
          align="left"
        />
      }
    >
      <SearchBar searchText={searchText} setSearchText={setSearchText} />
      <Orders searchText={searchText} />
    </PageContainer>
  );
};

export { PageExploreOrders };
